.blur-spotlight {
    position: absolute;
    width: 300px;
    height: 300px;
    /* background: radial-gradient(circle, rgba(255, 255, 255, 0.35), transparent); */
    background: radial-gradient(circle, rgba(0, 150, 255, 0.6), rgba(0, 0, 0, 0));
    filter: blur(80px);
    animation: move 15s ease-in-out infinite;
  }
  
  .blur-spotlight.delay {
    animation: move2 15s ease-in-out infinite;
    animation-delay: 6s;
    visibility: hidden;
  }
  
  .blur-spotlight.delay-2 {
    animation: move3 15s ease-in-out infinite;
    /* animation-delay: 2s; */
    visibility: hidden;
  }
  .blur-spotlight.delay-3 {
    animation: move4 15s ease-in-out infinite;
    animation-delay: 6s;
    visibility: hidden;
  }
  
  @keyframes move {
    0% {
        visibility: visible;
        transform: translate(-50%, -50%) translateY(1200px) translateX(700px) scale(1);
    }
    15% {
        transform: translate(-50%, -50%) translateY(900px) translateX(850px) scale(1.1);
    }
    30% {
        transform: translate(-50%, -50%) translateY(600px) translateX(500px) scale(1);
    }
    45% {
        transform: translate(-50%, -50%) translateY(300px) translateX(300px) scale(1.2);
    }
    60% {
        transform: translate(-50%, -50%) translateY(100px) translateX(700px) scale(1);
    }
    70% {
        transform: translate(-50%, -50%) translateY(100px) translateX(700px) scale(1);
    }
    80% {
        transform: translate(-50%, -50%) translateY(400px) translateX(1000px) scale(1);
    }
    100% {
        transform: translate(-50%, -50%) translateY(1200px) translateX(700px) scale(1);
    }
}

@keyframes move2 {
    0% {
        visibility: visible;
        transform: translate(-50%, -50%) translateY(1200px) translateX(1100px) scale(1);
    }
    20% {
        transform: translate(-50%, -50%) translateY(1000px) translateX(900px) scale(1.2);
    }
    40% {
        transform: translate(-50%, -50%) translateY(700px) translateX(600px) scale(0.9);
    }
    55% {
        transform: translate(-50%, -50%) translateY(300px) translateX(800px) scale(1.1);
    }
    70% {
        transform: translate(-50%, -50%) translateY(100px) translateX(400px) scale(1);
    }
    80% {
        transform: translate(-50%, -50%) translateY(200px) translateX(950px) scale(1.2);
    }
    100% {
        transform: translate(-50%, -50%) translateY(1200px) translateX(1100px) scale(1);
    }
}

@keyframes move3 {
    0% {
        visibility: visible;
        transform: translate(-50%, -50%) translateY(1200px) translateX(1700px) scale(1);
    }
    20% {
        transform: translate(-50%, -50%) translateY(1000px) translateX(1400px) scale(1.1);
    }
    40% {
        transform: translate(-50%, -50%) translateY(600px) translateX(1100px) scale(0.8);
    }
    50% {
        transform: translate(-50%, -50%) translateY(300px) translateX(1500px) scale(1.2);
    }
    70% {
        transform: translate(-50%, -50%) translateY(100px) translateX(1300px) scale(1);
    }
    85% {
        transform: translate(-50%, -50%) translateY(500px) translateX(1500px) scale(1.1);
    }
    100% {
        transform: translate(-50%, -50%) translateY(1200px) translateX(1700px) scale(1);
    }
}

@keyframes move4 {
    0% {
        visibility: visible;
        transform: translate(-50%, -50%) translateY(1200px) translateX(-100px) scale(1);
    }
    20% {
        transform: translate(-50%, -50%) translateY(1000px) translateX(400px) scale(1.1);
    }
    35% {
        transform: translate(-50%, -50%) translateY(600px) translateX(800px) scale(0.8);
    }
    50% {
        transform: translate(-50%, -50%) translateY(300px) translateX(600px) scale(1.2);
    }
    60% {
        transform: translate(-50%, -50%) translateY(100px) translateX(600px) scale(1);
    }
    80% {
        transform: translate(-50%, -50%) translateY(700px) translateX(200px) scale(1);
    }
    100% {
        transform: translate(-50%, -50%) translateY(1200px) translateX(-100px) scale(1);
    }
}
